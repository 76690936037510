/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Your app custom styles here */

.bingoGrid .bcell{
  width: calc(20% - 2px);
  height: 100px;
  border: 1px solid #ccc;
  float: left;
}